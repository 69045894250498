import * as React from 'react';
import DashboardDetailReducer from "./DashboardDetailReducer";
import LoggedScreen from "../../../base/screens/LoggedScreen";
import {connect} from "react-redux";
import ScreenContent from "../../../components/screen/ScreenContent";
import Row from "../../../components/Row";
import Col from "../../../components/Col";
import Card from "../../../components/card/Card";
import CardHeader from "../../../components/card/CardHeader";
import CardBody from "../../../components/card/CardBody";
import I18nUtils from "../../../I18n/I18nUtils";
import {TR_DASHBOARDS} from "../../../I18n/constants";
import {ROUTE_DASHBOARD} from "../../../routing/Routes";
import {MenuIcon} from "../../../Config";
import {RouteComponentProps} from "react-router";
import DashboardDetailActions from "./DashboardDetailActions";
import DashboardFilter from "../components/DashboardFilter";
import TaskDashboardDetail from "../../../ws/dashboard/TaskDashboardDetail";
import {DashboardDetailFilterData} from "../../../model/Dashboard";
import {loadItemDetailsOrGoBack} from "../../../utils/FormUtils";
/* import DetailCard from './DetailCard';
import Pagination from "../../../components/Pagination"; */
import PaginationDashboard from "./PaginationDashboard";

const mapStateToProps = DashboardDetailReducer.autoMapToProps();
const mapActionsToProps = DashboardDetailActions.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps & RouteComponentProps<{ id: string }>

class DashboardDetailScreen extends LoggedScreen<Props> {

    public constructor(props: Props, context: any) {
        super(props, context);

        this.addBreadcrumbScreen(I18nUtils.tr(TR_DASHBOARDS), ROUTE_DASHBOARD, MenuIcon.DASHBOARD)
        this.handleDashboardDetail = this.handleDashboardDetail.bind(this);

        this.state = {
            loading: false,
            request: {},
            currentPage: 1,
        }
    }

    handleCallback = (childData) => {
        this.setState({currentPage: childData})
    }

    public componentWillMount(): void {
        this.handleDashboardDetail();
    }

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    private handleDashboardDetail(request: DashboardDetailFilterData = {}): void {
        this.setState({request})
        loadItemDetailsOrGoBack(new TaskDashboardDetail(this.props.match.params.id, request), this.props);
    }

    private printDashboards(id, request) {
        this.setState({ loading: true });


        let requestString = JSON.stringify(request);
        //
        const currentPage = this.state['currentPage'];
        let iframe = document.createElement('iframe')
        // Comento esta línea para que funcione la impresión de solo los dashboards que están en la página..
        //iframe.src = `/dashboards/iframesscreen/id/${id}/request/${encodeURIComponent(requestString)}`;
        iframe.src = `/dashboards/iframesscreen/id/${id}/request/${encodeURIComponent(requestString)}/page/${currentPage}`;
        iframe.name = "allFrames";
        iframe.id = "allFrames";
        iframe.style.cssText = "opacity: 0; width: 1000px; height: 1000px";
        document.body.appendChild(iframe);

        const dashboards = document.getElementsByClassName('dashboard-item');

        iframe.onload = () => {
            setTimeout(() => {
                if (iframe.contentWindow)
                {
                    let frames = iframe.contentWindow.document.getElementsByTagName('iframe')

                    if (frames.length)
                        this.scrollFrames(iframe, frames, 0);
                }
            }, dashboards.length * 10000)
        }
    }

    private scrollFrames(iframe: HTMLIFrameElement ,frames: HTMLCollectionOf<HTMLIFrameElement>, nFrames): void {
        if (nFrames > frames.length - 1)
        {
            this.setState({ loading: false })
            iframe.style.cssText = "opacity: 1; width: 1000px; height: 1000px"
            this.printIFrame(iframe);
            return;
        }
        
        setTimeout(() => {
            frames[nFrames].scrollIntoView();
            this.scrollFrames(iframe, frames, ++nFrames);
        }, 500);
    }

    private printIFrame(iframe: HTMLIFrameElement) {
        if (iframe.contentWindow && iframe.parentNode)
        {
            iframe.contentWindow.focus();
            iframe.contentWindow.print();
            iframe.parentNode.removeChild(iframe);
        }
    }

    public renderScreen(): React.ReactNode {
        const { dashboard, loading } = this.props;
        return (
            <ScreenContent breadcrumbManager={this.getBreadcrumbManager()}>
                <Row>
                    <Col md={12}>
                        <Card loading={loading || this.state['loading']}>
                            {dashboard && <>
                              <CardHeader
                                title={dashboard.name}
                                onRefresh={() => this.forceUpdate()}
                                options={[
                                    {
                                        text: 'Quitar de favoritos',
                                        onClick: () => console.warn('favoritos'),
                                    },
                                    {
                                        text: "Imprimir",
                                        onClick: () => 
                                            this.printDashboards(dashboard.id, this.state['request'])
                                    }
                                ]}
                              />
                              <CardBody>
                                <Row>
                                    {dashboard &&
                                    <DashboardFilter
                                      filter={dashboard.filter}
                                      onFilter={this.handleDashboardDetail}
                                    />
                                    }
                                </Row>
                                <Row>
                                    {/* <Pagination
                                        data={dashboard.items}
                                        RenderComponent={DetailCard}
                                        title="Dashboards"
                                        buttonConst={3}
                                        contentPerPage={6}
                                        siblingCount={1}
                                        parentCallback ={this.handleCallback}
                                    /> */}
                                    <PaginationDashboard data={dashboard.items} parentCallback ={this.handleCallback}/>
                                </Row>
                              </CardBody>
                            </>}
                        </Card>

                    </Col>
                </Row>
            </ScreenContent>
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(DashboardDetailScreen as unknown as React.ComponentType<{}>)
