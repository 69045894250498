import React, { useState } from 'react';
import Col from "../../../components/Col";
import Row from "../../../components/Row";
import DropDown from "../../../components/dropdown/DropDown";
import I18nUtils from "../../../I18n/I18nUtils";
import {TR_COMPARAR_GRAFICOS, TR_IMPRIMIR} from "../../../I18n/constants";
import {generateRoute, goToRoute} from "../../../utils/Router";
import {ROUTE_COMPARE_GRAPHICS} from "../../../routing/Routes";
import Icon from "../../../components/Icon";
import LoadingView from '../../../base/loading/LoadingView';

const DetailCard = (props) => {
    const dashboardItem = props.data;
    const [loading, setLoading] = useState(false)

    const printDashboard =  (dashboardItem) => {
        setLoading(true);
        let iframe = document.createElement('iframe');
        iframe.src = `/dashboards/iframe/${encodeURIComponent(dashboardItem.url_iframe)}/title/${encodeURIComponent(dashboardItem.name)}`;
        iframe.name = `iframe_${dashboardItem.id}`;
        iframe.id = `iframe_${dashboardItem.id}`;
        iframe.style.cssText = "opacity: 0";
        document.body.appendChild(iframe);
        iframe.onload = () => {
            setTimeout(() => {
                setLoading(false);
                iframe.style.cssText = "opacity: 1";
                printIFrame(iframe);
            }, 20000);
        }
    }
    //
    const printIFrame = (iframe: HTMLIFrameElement) => {
        if (iframe.contentWindow && iframe.parentNode)
        {
            iframe.contentWindow.focus();
            iframe.contentWindow.print();
            iframe.parentNode.removeChild(iframe);
        }
    }
    //
    return(
        <>
            {
                <Col sm={6} md={6} lg={6} className='dashboard-item'>
                    <Row>
                        <Col sm={6}>
                            <h3 className={'m-l-15'}>{dashboardItem.name}</h3>
                        </Col>
                        <Col sm={6} className={'card-search-filter'}>
                            <DropDown className={'header-item'} pullRight={true} options={[
                                {
                                    text: I18nUtils.tr(TR_COMPARAR_GRAFICOS),
                                    onClick: () => {
                                        goToRoute(
                                            generateRoute(ROUTE_COMPARE_GRAPHICS, {
                                                graphic: dashboardItem.id,
                                                dashboard: dashboardItem.id
                                            }))
                                    }
                                }
                                ,
                                {
                                    text: I18nUtils.tr(TR_IMPRIMIR),
                                    onClick: () => printDashboard(dashboardItem)
                                }]}>
                                <Icon icon={'more_vert'}/>
                            </DropDown>
                        </Col>
                    </Row>

                    <div className={'iframe'}>
                        <iframe
                            src={dashboardItem.url_iframe}
                            height={'100%'}
                            width={'100%'}
                        />
                    </div>
                </Col>
            }
            <LoadingView loading={loading || false}/>
        </>
    );
    //
}
export default DetailCard;