import React, { useState } from 'react';
import { usePaginationRange, DOTS } from "../../../hooks/usePaginationRange";
import DetailCard from "./DetailCard";

const PaginationDashboard = ({data, parentCallback}) => {
    const dashboards = data;
    const contentPerPage = 6;
    const buttonConst = 3;
    const siblingCount = 0;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageCount] = useState(Math.ceil(data.length / contentPerPage));

    const goToNextPage = () => {
        setCurrentPage((page) => page + 1);
        parentCallback((page) => page + 1);
    }
    const gotToPreviousPage = () => {
        setCurrentPage((page) => page - 1);
        parentCallback((page) => page - 1);
    }
    const changePage = (event) => {
        const pageNumber = Number(event.target.textContent);
        setCurrentPage(pageNumber);
        parentCallback(pageNumber);
    }

    const paginationRange = usePaginationRange({
        totalPageCount,
        contentPerPage,
        buttonConst,
        siblingCount,
        currentPage
    });

    return(
        <>
        {
            dashboards.map((dashboardItem, index) =>
            (index>=(currentPage-1)*contentPerPage && index<(currentPage-1)*contentPerPage+contentPerPage) && <DetailCard data={dashboardItem}/>)
        }
        <nav className={'row footer-pagination'}>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 m-b--10">
                    {/* previous button */}
                    <ul
                        className={'pagination'}
                    >
                        <li
                            className={` prev ${currentPage === 1 ? "disabled" : ""}`}
                        >
                            <a className="waves-effect" onClick={gotToPreviousPage}>&lt;</a>
                        </li>
                        {/* show paginated button group */}
                        {paginationRange && paginationRange.map((item, index) => {
                            if (item === DOTS) {
                                return (
                                    <li key={index} className={`paginationItem`}>
                                        &#8230;
                                    </li>
                                );
                            }
                            return (
                                <li
                                    key={index}
                                    className={`paginationItem ${
                                        currentPage === item ? "active" : null
                                    }`}
                                >
                                    <a className="waves-effect" onClick={changePage}>{item}</a>
                                </li>
                            );
                        })}
                        {/* next button */}
                        <li
                            className={`next ${currentPage === totalPageCount ? "disabled" : ""}`}
                        >
                            <a className="waves-effect" onClick={goToNextPage}>&gt;</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    ) 
}

export default PaginationDashboard;